#app-airport-load-container {
  display: flex;
  justify-content: right;
}

#app-airport-load i {
  padding-right: 10px;
}

.app-airport-actions {
  padding: 10px;
  padding-bottom: 0;
}
