.program-print-elevation > canvas {
  max-height: 200px;
}

.program-print-elevation > div {
  margin-bottom: 10px;
}

.program-print-map > div,
.program-print-map > img {
  position: relative;
  margin: 10px 0;
  height: 300px;
}

.sheet > :first-child .program-print-map > div,
.sheet > :first-child .program-print-map > img {
  margin-top: 0;
}

.program-print-map .loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
