.tp-table-translatable {
  position: relative;

  & .flag-icon {
    position: absolute;
    top: 12px;
    right: -5px;
    height: 10px;
  }

  &.tp-table-markdown .flag-icon {
    top: 30px;
  }
}

.tp-table-subform-array .tp-table-translatable .flag-icon {
  position: relative;
  top: 0;
  left: 2px;
}
