.tp-core-console-executed {
  height: calc(100vh - 245px);
  border: 2px solid #444;
  overflow: auto;
  background-color: #444;
}

.tp-core-console-executed-command {
  color: white;
  padding: 5px 10px;
  border-bottom: 1px solid black;
  background-color: #333;
  cursor: pointer;
}

.tp-core-console-executed pre {
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.tp-core-console-command {
  height: 40px;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  border: 2px solid #444;
  margin-bottom: 15px;
}

.tp-core-console-command span {
  text-align: center;
}

.tp-core-console-command input {
  height: 100%;
  border: 0;
  outline: none;
}

.tp-core-console-command input:read-only {
  color: #999;
}
