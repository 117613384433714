:root {
  --green: #267e33;
  --light-green: #cae3c9;
}

.app-dolibarr-queue-title {
  border-bottom: 1px solid grey;
}

.app-dolibarr-queue-running {
  position: absolute;
}

.app-dolibarr-queue-reload {
  cursor: pointer;
  text-decoration: underline;
}

.success {
  border: solid 1px var(--green);
  background-color: var(--light-green);
  padding: 12px 0 4px 20px;
}

.success-msg,
.success-icon {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.success-icon {
  margin-right: 20px;
  font-size: 30px;
  color: var(--green);
}

.success-msg {
  margin-bottom: 9px;
}
