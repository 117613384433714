.ui-dialog .ui-dialog-titlebar-close {
  display: none;
}

.ui-dialog .tp-core-dialog-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

/* JQuery UI Customisation */
.ui-widget {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.ui-widget-header {
  background: #ddd;
  border-color: #ccc;
  border-radius: 0;
}

.ui-widget-overlay {
  z-index: 2000;
}

.ui-dialog {
  z-index: 2001;
}

.ui-widget button {
  font-family: inherit;
  font-size: 12px;
}

.ui-widget-content a.btn-primary {
  color: #fff;
}
