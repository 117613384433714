.tp-table-images-container img {
  max-width: 100%;
  max-height: 100%;
}

.tp-table-images-container {
  text-align: center;
  width: 100%;
  height: 150px;
  line-height: 145px;
  background-color: #c6c6c6;
  border: 1px solid #999;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.tp-table-images-interact {
  cursor: pointer;
}

.tp-table-images-interact.tp-table-images-selected {
  box-shadow: 0 0 5px #f00;
  border-color: #f00;
}

.tp-table-images-more {
  display: inline-block;
  line-height: initial;
  vertical-align: middle;
}

.tp-table-images-more button {
  margin-bottom: 10px;
}

.tp-table-images-more button:last-child {
  margin-bottom: 0;
}

.tp-table-images-display-available {
  width: 100%;
}

/* Form */
form .vich-image img {
  max-width: 100%;
  max-height: 300px;
}

/* Zoom */
.app-zoom-content .tp-table-images-container {
  margin: 0;
}
