.program-print-config-break-selected,
.program-print-config-break-selectable, /* do not add :not(:first-child) so that positioning with other elements do not differ for the first child (ex: optional activities) */
.program-print-config-hide-selected,
.program-print-config-hide-selectable {
  position: relative;
  margin-left: -30px;
  padding-left: 30px;
}

.program-print-config-hide-selected {
  position: absolute !important;
  width: 100%;
  height: 1px;
}

@media print {
  .program-print-config-hide-selected {
    display: none;
  }
}

.program-print-config-hide-selected::before {
  display: block;
  width: 94%;
  margin-top: -10px;
  content: '';
  border-top: 1px dashed #999;
}

.program-print-config-hide-selected > :last-child {
  display: none;
}

.program-print-config-break-selected:hover,
.program-print-config-break-selectable:not(:first-child):hover,
.program-print-config-hide-selected:hover,
.program-print-config-hide-selectable:hover {
  background-color: #eee;
}

.program-print-config-action-container {
  display: none;
  position: absolute;
  left: 0;
  top: 1px;
}

.program-print-config-hide-selected .program-print-config-action-container {
  top: -19px;
}

.program-print-config-break-action,
.program-print-config-hide-action {
  display: none;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.program-print-config-break-action {
  background-image: url('../images/pagebreak.png');
}

.program-print-config-hide-action {
  margin-left: 2px;
  background-image: url('../images/visible.png');
}

.program-print-config-hide-selected .program-print-config-hide-action {
  display: block;
}

.program-print-config-hide-selected .program-print-config-break-action {
  display: none !important;
}

.program-print-config-hide-selected .program-print-config-action-container,
.program-print-config-hide-selectable:hover
  .program-print-config-action-container,
.program-print-config-break-selected:hover
  .program-print-config-action-container,
.program-print-config-break-selectable:not(:first-child):hover
  .program-print-config-action-container,
.program-print-config-hide-selected:hover .program-print-config-hide-action,
.program-print-config-hide-selectable:hover .program-print-config-hide-action,
.program-print-config-break-selected:hover .program-print-config-break-action,
.program-print-config-break-selectable:not(:first-child):hover
  .program-print-config-break-action {
  display: block;
}

.program-print-config-break-selected .program-print-config-break-action::before,
.program-print-config-break-selected .program-print-config-break-action::after,
.program-print-config-hide-selected .program-print-config-hide-action::before,
.program-print-config-hide-selected .program-print-config-hide-action::after {
  position: absolute;
  left: 10px;
  content: '';
  height: 20px;
  width: 2px;
  background-color: #f00;
}

.program-print-config-break-selected .program-print-config-break-action::before,
.program-print-config-hide-selected .program-print-config-hide-action::before {
  transform: rotate(45deg);
}

.program-print-config-break-selected .program-print-config-break-action::after,
.program-print-config-hide-selected .program-print-config-hide-action::after {
  transform: rotate(-45deg);
}
