/* Subforms Array */
.tp-table-subform-array-label {
  font-weight: 700;
  padding-left: 25px;
}

/* Subforms Section */
.tp-table-subform-section-delete {
  margin-top: 5px;
}

.tp-table-subform-section-label {
  text-align: center !important;
}

/* Zoom */
/* stylelint-disable  selector-class-pattern */
.app-zoom-content.tp_table_subform {
  padding: 0;
}
/* stylelint-enable  selector-class-pattern */

.app-zoom-content .table {
  margin-bottom: 0;
}
