/* Layout */
#app-documentation-print .wrapper {
  overflow: auto;
}

/* Front Page */
#app-documentation-print .program-print-logo {
  margin: 10mm;
  height: 40mm;
}

.tp-core-doc-title {
  margin: 20mm 10mm 10mm;
  text-align: center;
  font-size: 50px;
}

/* Table of Contents */
#app-table-of-contents {
  height: 150mm;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  border: 5px double black;
}

#app-table-of-contents h1 {
  margin-top: 0;
}

#app-table-of-contents .tp-core-title-h2 {
  margin-left: 30px;
}

#app-table-of-contents .tp-core-title-h3 {
  margin-left: 60px;
}

#app-table-of-contents .tp-core-title-h4 {
  margin-left: 90px;
}

#app-table-of-contents .tp-core-title-h5 {
  margin-left: 120px;
}

/* Content */
#app-documentation-print p {
  text-align: justify;
}

#app-documentation-print img {
  display: block;
  margin: auto;
  max-width: 80% !important;
  max-height: 500px !important;
}
