.tp-table-map,
.tp-table-map-zoom {
  cursor: pointer;
}

.tp-table-map-zoom:hover {
  background-color: #eee;
}

.tp-table-map-container,
.tp-table-map-container-zoom {
  position: relative;
  display: flex;
  height: 400px;
  background-color: #eee;
}

.tp-table-map-location-zoom {
  padding-left: 5px;
}

.tp-table-map-container-zoom {
  margin-top: 5px;
  border-top: 1px solid #aaa;
}

.tp-table-map-container .loading,
.tp-table-map-container-zoom .loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tp-table-map-elevation-container canvas {
  padding-top: 10px;
  max-height: 200px;
}

.tp-table-map-elevation-container div {
  padding-top: 10px;
}

.tp-table-map-scroll {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: rgba(0 0 0 / 50%);
  color: #ddd;
}

.tp-table-map-camera-position-save .mapboxgl-ctrl-icon {
  background-image: url('../images/print-view.png');
}

.tp-table-map-camera-position-delete {
  transition: height 300ms ease-in-out;

  & .mapboxgl-ctrl-icon {
    background-image: url('../images/delete.png');
  }
}

.tp-table-map-camera-position-no-delete
  button.tp-table-map-camera-position-delete {
  height: 0;
  box-shadow: none;
}

.app-map-layer {
  position: relative;
}

.app-map-layer-list-toggle .mapboxgl-ctrl-icon {
  background-image: url('../images/layer.png');
}

.app-map-layer-list {
  position: absolute;
  top: 0;
  left: 35px;
  width: 90px;
  background-color: #fff;
  border-radius: 4px;

  & button {
    width: 100%;
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-gap: 5px;
    align-items: center;
    justify-items: left;
  }
}

.app-map-layer-streets {
  background-image: url('../images/road.png');
}

.app-map-layer-outdoors {
  background-image: url('../images/landscape.png');
}

.app-map-layer-satellite {
  background-image: url('../images/satellite.png');
}

.app-map-link-markers-toggle .mapboxgl-ctrl-icon {
  background-image: url('../images/link-markers.png');
}

.app-map-display-elevation-toggle .mapboxgl-ctrl-icon {
  background-image: url('../images/elevation.png');
}

.tp-table-map-print-view {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 688px 1fr;
  grid-template-rows: 1fr 300px 1fr;
}

.tp-table-map-print-view-border {
  background-color: #000;
  opacity: 0.3;
}

.tp-table-map-print-view-content {
  position: relative;
  border: 1px solid #222;
  text-align: center;
}

.tp-table-map-print-view-content span {
  position: relative;
  top: -25px;
  padding: 5px;
  background-color: #222;
  color: #fff;
}

.tp-table-map-marker-delete {
  display: flex;
  align-items: center;
  margin: 1px 0 -6px;
  cursor: pointer;
}

.tp-table-map-marker-cluster {
  text-align: center;
  margin-bottom: -5px;

  & div:first-child {
    margin: 1px 0 -5px;
    font-weight: bold;
  }

  & div:last-child {
    font-size: smaller;
  }
}

.tp-map-pictograms-container {
  display: grid;
  grid-template-columns: 220px 1fr;
  column-gap: 5px;
  margin-bottom: -5px;
}

.tp-map-pictograms-pins-container {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 24px 1fr;
  grid-gap: 1px;

  & :first-child {
    grid-column: span 2;
  }
}

.tp-map-pictograms-title {
  margin-bottom: 3px;
  border-bottom: 1px solid #aaa;
}

.tp-map-pictograms-img {
  margin: 0 1px;
  cursor: pointer;
  max-height: 25px;
}

.tp-map-pictograms-line {
  display: flex;
  justify-content: space-between;
  max-width: 220px;
}

.mapboxgl-control-container {
  z-index: 20;
}

@media print {
  .mapboxgl-control-container {
    display: none;
  }
}
