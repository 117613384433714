.warning {
  position: relative;
  margin-bottom: 25px;
  border: solid 1px #ff9f17;
  background-color: #fffcf7;
}

.warning-msg,
.warning-icon {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.warning-icon {
  position: absolute;
  margin: 20px;
  font-size: 30px;
  color: #ff9f17;
}

.warning-msg {
  margin: 10px 10px 10px 70px;
}

.warning-msg ul {
  display: block;
  margin-left: 10px;
}
