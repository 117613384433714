/* Day Activity */
.tp-planner-activity-optional {
  margin-left: 5px;
  font-style: italic;
  font-weight: bold;
}

.app-add-activity-buttons {
  float: right;
}

.tp-planner-activity-delete {
  margin-top: 5px;
}

div.well {
  margin-right: 0;
  border-color: #aaa;
}

/* stylelint-disable  selector-id-pattern */
#day_activities > div > div > label,
#day_template_activities > div > div > label {
  text-align: center;
  text-decoration: underline;
}
/* stylelint-enable  selector-id-pattern */
