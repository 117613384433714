.tp-table-list-buttons-title {
  width: 105px;
}

.tp-table-list-buttons {
  max-width: 105px;
  float: right;
}

.app-no-record {
  margin: 20px 10px;
  padding: 20px 30px;
  border-radius: 5px;
  color: #286090;
  border: 1px solid #286090;
}
