.tp-table-markdown {
  position: relative;
}

.tp-table-markdown span {
  position: absolute;
  top: 10px;
  left: -26px;
  cursor: pointer;
}

.tp-table-markdown-link {
  color: red;
}

.tp-table-markdown-doc {
  float: right;
  text-decoration: underline;
}

.tp-table-markdown-line-break {
  display: inline-block;
  width: 16px;
  height: 18px;
  background-image: url('./images/line-break.png');
  background-size: cover;
}

.tp-table-markdown-space {
  display: inline-block;
  width: 15px;
  height: 5px;
  border: 1px #666 solid;
  border-top: none;
}
