/* stylelint-disable  selector-id-pattern */
#Value_program_daysList {
  padding: 0;
}
/* stylelint-enable  selector-id-pattern */

.program-day,
.program-th-day {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.program-day i,
.program-th-day i {
  margin-top: 2px;
  margin-right: 5px;
}

.program-activities {
  max-height: 2000px;
  transition: max-height 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.program-activities-hidden {
  max-height: 0;
  overflow: hidden;
}

.program-activities-container {
  margin-top: 10px;
  padding: 5px;
  border-top: 1px solid #ddd;
}

.program-activities-title {
  font-weight: bold;
  font-style: italic;
}
