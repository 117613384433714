.program-print-left-panel {
  position: fixed;
  top: 5mm;
  left: 5mm;
  display: inline-block;
  z-index: 10;
}

@media print {
  .program-print-left-panel {
    display: none;
  }
}

/* Templates */
.program-print-template {
  display: block;
  padding: 7px 11px;
  background-color: #fff;
  box-shadow: 0 0.5mm 2mm rgba(0 0 0 / 30%);
}

.program-print-template,
.program-print-template:hover,
.program-print-template:focus,
.program-print-template:visited,
.program-print-template:active {
  text-decoration: none;
  color: #000;
}

.program-print-template:hover,
.program-print-template:focus {
  background-color: #ddd;
}

.program-print-template + .program-print-template {
  margin-top: 5px;
}

.program-print-template-selected {
  background-color: #e6e6e6;
}

/* Print Configuration Form */
form[name='print_configuration'] {
  display: none;
}

/* Notebook */
.program-print-notebook-option {
  margin-top: 10px;
}

.program-print-notebook-option button {
  width: 100%;
  padding: 7px 11px;
  text-align: left;
}

.program-print-notebook-option button span:first-child {
  margin-right: 5px;
  font-size: 12px;
}

.program-print-notebook-option button span:last-child {
  font-size: 14px;
  color: #000;
}
