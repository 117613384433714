.app-zoom-form {
  margin-bottom: 20px;
  padding: 0 10px 10px;
  border: solid 1px #e3e3e3;
  background-color: #f5f5f5;
}

.app-zoom-section,
.app-zoom-label,
.app-zoom-content {
  margin: 2.5px;
  padding: 5px;
}

.app-zoom-section {
  margin-top: 20px;
  padding: 10px;
  border: solid 1px #bbb;
  background-color: #ddd;
  font-weight: bold;
}

.app-zoom-label {
  font-weight: bold;
  font-style: italic;
  text-align: right;
}

.app-zoom-content {
  min-height: 32px;
  background-color: #fff;
  border: solid 1px #eee;
}

/* Markdown */
/* stylelint-disable  selector-class-pattern */
.tp_table_textarea :last-child {
  margin-bottom: 0;
}
/* stylelint-enable  selector-class-pattern */

.app-page-bottom h4 {
  margin-left: 50px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #e3e3e3;
}
