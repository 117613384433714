.sheet.program-print-notebook {
  overflow: visible;
}

.sheet:nth-child(1n),
.program-print-contacts p {
  transition: padding 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.sheet::before {
  display: block;
  position: absolute;
  top: 15px;
  width: 75px;
  height: 96%;
  content: '';
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

@media print {
  .sheet::before {
    display: none;
  }
}

.sheet.program-print-notebook::before {
  opacity: 0.5;
}

.sheet.program-print-notebook:nth-child(odd) {
  padding-left: 15mm;
  padding-right: 5mm;

  &::before {
    left: -34px;
    background: repeat-y url('../images/spiral.png');
  }

  & .program-print-contacts p {
    padding-left: 15mm;
  }
}

.sheet.program-print-notebook:nth-child(even) {
  padding-left: 5mm;
  padding-right: 15mm;

  &::before {
    right: -34px;
    background: repeat-y url('../images/spiral-right.png');
  }

  & .program-print-pagination-logo {
    right: 29px;
  }
}
