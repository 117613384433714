#app-button-bar-container,
#app-popup-bar {
  background-color: #c9c9c9;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #777;
}

#app-button-bar-container {
  position: sticky;
  bottom: 0;
  z-index: 1500;
}

[data-env='dev'] #app-button-bar-container {
  padding-bottom: 55px;
}

.app-button-bar > * + * {
  margin-left: 5px;
}

.app-form-button-bar > :first-child {
  margin-left: 10px;
}

#app-popup-bar-submit-button {
  float: right;
}

/* Override Bootstrap default color in the Buttons Bar */
#app-button-bar-container .btn-default {
  background-color: #c9c9c9;
  border-color: #aaa;
}

#app-button-bar-container .btn-default:hover,
#app-button-bar-container .btn-default:focus {
  background-color: #bbb;
  border-color: #999;
}
