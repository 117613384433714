.tp-table-widget-with-help {
  position: relative;
}

.tp-table-widget-with-help span {
  position: absolute;
  top: 10px;
  left: -26px;
  cursor: pointer;
}
