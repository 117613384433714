.tp-table-map {
  position: relative;
}

.tp-table-map .tp-table-helper {
  position: absolute;
  top: 10px;
  left: -26px;
  cursor: pointer;
}

.app-table-helper-map-container {
  display: flex;
  align-items: center;
}

.app-table-helper-map-container-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
}

.app-table-helper-map-content,
.app-table-helper-map-container {
  margin-left: 20px;
}

.app-table-helper-map-global-view {
  width: 100%;
  height: 320px;
  background: url('./images/map-global-view.png') no-repeat center;
  background-size: contain;
}

.app-table-helper-map-elevation {
  width: 50px;
  height: 40px;
  background: url('./images/map-elevation.png') no-repeat center;
  background-size: contain;
  margin-right: 10px;
}

.app-table-helper-map-layers {
  width: 160px;
  height: 80px;
  background: url('./images/map-layers.png') no-repeat center;
  background-size: contain;
  margin: 0 10px 10px 0;
}

.app-table-helper-map-layer-streets {
  width: 160px;
  height: 80px;
  background: url('./images/map-layer-streets.png') no-repeat center;
  background-size: contain;
}

.app-table-helper-map-layer-outdoors {
  width: 160px;
  height: 80px;
  background: url('./images/map-layer-outdoors.png') no-repeat center;
  background-size: contain;
}

.app-table-helper-map-layer-satellite {
  width: 160px;
  height: 80px;
  background: url('./images/map-layer-satellite.png') no-repeat center;
  background-size: contain;
}

.app-table-helper-map-geocoding {
  width: 260px;
  height: 150px;
  background: url('./images/map-geocoding.png') no-repeat center;
  background-size: contain;
  margin-right: 10px;
}

.app-table-helper-map-link-markers {
  width: 40px;
  height: 40px;
  background: url('./images/map-link-markers.png') no-repeat center;
  background-size: contain;
  margin-right: 10px;
}

.app-table-helper-map-camera {
  width: 64px;
  height: 80px;
  background: url('./images/map-camera.png') no-repeat center;
  background-size: contain;
  margin-bottom: 10px;
}

.app-table-helper-map-print-view-button {
  width: 75px;
  height: 50px;
  background: url('./images/map-save-print-view-button.png') no-repeat center;
  background-size: contain;
  margin-bottom: 10px;
}

.app-table-helper-map-print-view {
  width: 100%;
  height: 170px;
  background: url('./images/map-save-print-view.png') no-repeat center;
  background-size: contain;
}
