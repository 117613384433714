/* Copy & Adaptation of "flag-icon-css" module
   (so that it works exactly like an import of the module but without importing everything) */
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.3333em;
  line-height: 1em;
}

.flag-icon-fr {
  background-image: url('~flag-icon-css/flags/4x3/fr.svg');
}

.flag-icon-gb {
  background-image: url('~flag-icon-css/flags/4x3/gb.svg');
}
