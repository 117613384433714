.error-img {
  height: 250px;
  margin-top: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.error-img-403 {
  background-image: url('./images/error-403.png');
}

.error-img-404 {
  background-image: url('./images/error-404.png');
}

.error-img-500 {
  background-image: url('./images/error-500.png');
}

.error-msg {
  text-align: center;
}

h3.error-msg {
  font-weight: bold;
}

h3.error-msg + h3.error-msg {
  margin-top: 10px;
}

h4.error-msg {
  margin: 20px auto;
}

.error-alert {
  margin-top: 0;
  text-align: center;
}
