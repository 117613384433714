:root {
  --border: 1px solid #ccc;
}

.program-header {
  margin: 10px auto;
  border-top: var(--border);
  border-bottom: var(--border);
}

.program-header-actions-title {
  margin: 5px 0;
  font-size: large;
  text-align: center;
  border-bottom: var(--border);
}

.program-header-actions {
  list-style-type: none;
  padding-left: 0;

  li {
    padding: 5px;

    i {
      padding-left: 5px;
      padding-right: 10px;
    }
  }

  .program-header-add {
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
  }

  .program-header-add:last-child {
    margin-bottom: 0;
  }
}

.program-header-actions-separator {
  margin-bottom: 8px;
  border-bottom: var(--border);
}

.program-header-lists {
  display: grid;
  grid-template-columns: max(10%, 140px) auto;
  grid-template-rows: auto;
  align-content: center;
  border-right: var(--border);
  min-height: 185px;
}

.program-header-list-title {
  min-height: 55px;
  margin-left: 15px;
  padding-top: 16px;
  padding-right: 25px;
  text-align: right;
  border-bottom: var(--border);
}

.program-header-list {
  padding: 10px 0;
  margin: 0;
  border-bottom: var(--border);

  li {
    position: relative;
    margin-right: 5px;
    margin-bottom: 1px;
  }

  li:last-child {
    margin-right: 0;
  }

  i.glyphicon-ok {
    position: absolute;
    top: -7px;
    right: -5px;
    color: #5cb85c;
  }

  i.glyphicon-warning-sign {
    position: absolute;
    top: 23px;
    right: -5px;
    color: #ff9f17;
  }
}

.program-header-list-title:nth-last-child(2),
.program-header-list:last-child {
  border-bottom: none;
}

.program-header-active {
  background-color: #e6e6e6;
}

.program-header-interactive,
.program-header-interactive:hover,
.program-header-interactive:focus,
.program-header-interactive:visited,
.program-header-interactive:active {
  color: #333;
  text-decoration: none;
  cursor: pointer;

  a {
    color: #333;
    text-decoration: none;
  }
}

.program-header-interactive:hover,
.program-header-interactive:focus {
  background-color: #eee;
}

#program-header-days li {
  width: 42px;
}
