/* Accomodation Stars */
.service-stars-container {
  white-space: nowrap;
}

.service-stars-sup {
  font-size: 80%;
  vertical-align: top;
  margin-left: 3px;
  color: #666;
}
