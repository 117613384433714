/* Cards */
.card {
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0 0 0 / 20%), 0 6px 20px 0 rgba(0 0 0 / 19%);
}

.card-title,
.card-content {
  padding: 5px;
}

.card-content-margin {
  margin-top: 15px;
}

.card-title {
  position: relative;
  text-transform: uppercase;
  background-color: #ddd;
  border-bottom: 1px solid #bbb;
}

.card-property {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-property-title {
  font-weight: bold;
}

.card-property-title::after {
  content: ' :';
}

.card-popup-button {
  width: 100%;
}

/* Lists */
/* stylelint-disable selector-class-pattern */
.sorting_desc,
.sorting_asc {
  cursor: default !important;
}
/* stylelint-enable selector-class-pattern */

/* Next Departure */
.card-next-departure-zoom {
  position: absolute;
  right: 10px;
  top: 10px;
}

.card-next-departure-interval {
  margin-bottom: 15px;
  text-align: center;
  font-size: 25px;
}

/* Program Status */
.card-programs-status-no-program {
  padding: 20px 30px;
  border-radius: 5px;
  color: #286090;
  border: 1px solid #286090;
}

/* Owners Revenue */
#card-owners-revenue-for-whole-team th {
  width: 33.333%;
}

#card-show-owner-revenue {
  position: absolute;
  top: 40px;
  left: 0;
  margin: 0 25%;
  width: 50%;
}
