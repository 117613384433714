:root {
  --footer-color: #000;
  --footer-height: 41px;
}

/* Containers */
* {
  margin: 0;
}

html,
body {
  height: 100%;
}

.body-bottom-margin {
  /* done like that to prevent a scrolling bar all the time */
  margin-bottom: 20px;
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto calc(0 - var(--footer-height));
  background: url('../images/background.jpg') no-repeat center fixed;
  background-size: cover;
  background-color: #ccc;
}

.footer {
  height: var(--footer-height);
  border-top: 1px solid var(--footer-color);
  color: #818181;
  background-color: var(--footer-color);
  padding: 10px 0;
}

.push {
  height: var(--footer-height);
}

.body .container {
  background-color: #fff;
}

/* Logo */
.logo {
  height: 100%;
  display: block;
  margin: auto;
}

/* Loading */
.loading {
  width: 100px;
  height: 100px;
  margin: auto;
  background-image: url('../images/loadingCompass.gif');
  background-size: 100px;
  background-repeat: no-repeat;
}

.small-loading {
  width: 33px;
  height: 33px;
  margin: auto;
  background-image: url('../images/loadingCompass.gif');
  background-size: 33px;
  background-repeat: no-repeat;
}

.input-group-addon .small-loading {
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: #eee;
}

/* Menu */
.navbar-logo {
  position: relative;
  top: -18.5px;
  width: 80px;
  height: 55px;
}

.site-logo {
  display: inline-block;
  background-image: url('../images/site-logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.site-logo-invert {
  filter: invert(1);
}

.site-company-logo {
  object-fit: contain;
}

.tp-flag-icon {
  height: 15px;
}

/* FlashBag */
.alert {
  margin-top: 20px;
}

/* Dark Mode */
.dark-mode-button {
  position: absolute;
  right: 0;
  margin: 15px;
  color: #9d9d9d;
  cursor: pointer;
}

.dark-mode-button:hover {
  color: #fff;
}

.dark-mode,
.dark-mode .ui-dialog {
  filter: invert(1) hue-rotate(200deg);
}

body.dark-mode,
.navbar .dark-mode,
.footer .dark-mode {
  filter: none;
}

.dark-mode .flag-icon,
.dark-mode img {
  filter: invert(1) hue-rotate(-200deg);
}

.navbar .dark-mode .flag-icon {
  filter: none;
}
